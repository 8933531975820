import axios from 'axios';
import React, { useEffect, useState } from 'react';
import
{ IonPage
, IonButton
, IonImg
, IonInput
, IonText
, IonContent
, useIonAlert
, IonAlert
} from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

//Utils
import logger from '../../../utils/logger';
import UAParser from 'ua-parser-js';
import { loginTypeFromValue } from '../../../utils/validateData';
import { ctxValue } from '../../../utils/config';
import { useToast } from '@agney/ir-toast';
import { setAccessToken } from '../../../utils/session';
import { startAuthentication } from '@simplewebauthn/browser';
import 
{ acceptAgreements
, loginVerificationCodeStart
, loginPreviousVerificationCookie
, getAuthRequestInfo
, verifyResponse
, getEnrollRequestInfo
, logSigninEvent
} from '../../../utils/services';
/**
 * The front end's primary job during Authentication is the following:
 *  1. Get authentication options from the Relying Party (your server)
 *     - See @simplewebauthn/server's generateAuthenticationOptions()
 *  2. Submit authentication options to the authenticator
 *  3. Submit the authenticator's response to the Relying Party for verifications
 *     - See @simplewebauthn/server's verifyAuthenticationResponse()
 */

//Contexts
import { useLogin } from '../../../context/LoginContext';
import { useFIDO } from '../../../context/FIDOContext';
import { useVerifyCounter } from '../../../context/VerifyCounterContext';
import { useRP } from '../../../context/RPContext';

//Styles
import styles from './SignIn.module.css';

const userAgent = new UAParser();

function SignIn() {
  const history = useHistory();
  const Toast = useToast();
  const [ showIonAlert ] = useIonAlert();
  const { enrollKey, authKey, customCode } = useParams();
  const { subdomain, setSubdomain, buttonStyles, setButtonStyles } = useRP();
  const [ styledButtonHover, setStyledButtonHover ] = useState(false);
  const [ styledButtonFocus, setStyledButtonFocus ] = useState(false);
  const [ processing, setProcessing ] = useState(true);
  const { loginValue, setLoginValue } = useLogin();
  const { setFIDO } = useFIDO();
  const { setVerifyCounter } = useVerifyCounter();
  const [ signInButtonText, setSignInButtonText] = useState('SIGN IN');
  const [ userAgreementInfo, setUserAgreementInfo ] = useState();
  const [ requestInfo, setRequestInfo ] = useState({userAgreement:'',name:'',callCenterPhoneNumber:'',notificationType:'',verificationRequestId:'',preenrollAuthenticated:false});
  const [ authRequestStatus, setAuthRequestStatus ] = useState();
  const [ enrollRequestStatus, setEnrollRequestStatus]  = useState();
  const [ launchedFromHomeScreen, setLaunchedFromHomeScreen ] = useState(false);
  const [ loginMonikerAttemptFailed, setLoginMonikerAttemptFailed ] = useState(false);
  const [ showHeaders, setShowHeaders ] = useState(true);
  const [ signinRequest, setSigninRequest ] = useState();
  const [ showVerificationCodeAlert, setShowVerificationCodeAlert ] = useState(false);
  const [ userVerifyDialedNumberAlert, setUserVerifyDialedNumberAlert ] = useState(false);
  
  // fired once when the component (page) first loads
  useEffect(() => {
     async function __getAuthRequestInfo(authKey, customCode) {
      const response = await getAuthRequestInfo(authKey, customCode);
      setAuthRequestStatus(response?.status);

      if (response?.status!==200 && response?.status!==404) {
        Toast.create({ message: response?.message, color: 'danger', position: 'top', duration: 2500 }).present();
        return;
      }
      // Twilio's verify serivce can override the authKey to a prior version in short time periods (5mins)
      // The DB detects this and returns the correct authKey in correctedAuthKey 
      let _signinReq = { signinType: 'a', canRegisterDevice: response.data?.canRegisterDevice };
      _signinReq['requestKey'] = (response.data?.correctedAuthKey) ? response.data.correctedAuthKey : authKey;
      setSigninRequest(_signinReq);

      // success, update all the returned data
      if (response.status===200) {
        setRequestInfo(
          { name: response.data?.name
          , userAgreement: response.data?.userAgreement
          , callCenterPhoneNumber: response.data?.callCenterPhoneNumber 
          , userVerifyDialedNumber: response.data?.userVerifyDialedNumber
          , agentName: response.data?.agentName
          , notificationType: response.data?.notificationType
          , verificationRequestId: response.data?.verificationRequestId
          , preenrollAuthenticated: response.data?.preenrollAuthenticated
          });
        setSignInButtonText(response.data?.signInButtonText);
        setVerifyCounter(response.data?.verifyCounter);
        setLoginValue(response.data?.notificationValue);
        setSubdomain(response.data?.subdomain);
        setAccessToken(response.data.token, false);
        if (response.data?.claimsDeviceCheckAdded===1) {
          logger.event('device-check'); // send toast to listening IDgo Agents
        }
        if (response.data?.userVerifyDialedNumber==='true') {
          setUserVerifyDialedNumberAlert(true);
        }
        if (response.data?.buttonStyles) {
          setButtonStyles(response.data.buttonStyles);
        }
        if (response.data?.cookieAuthenticated===true) {
          await logSigninEvent(_signinReq, 10.00, {signinRequest:_signinReq});
          // need an access token and cookieAuthenticated to be true to go directly to the dashboard for verification approval
          history.replace({ pathname:'/dashboard', search: history.location.search, state:{ signinRequest:_signinReq }});
          return;
        }
      }

      setProcessing(false);
    }

    if (authKey && customCode) {
      __getAuthRequestInfo(authKey, customCode);
      return;
    }

    setProcessing(false);

    async function __getEnrollRequestInfo(enrollKey) {
      const response = await getEnrollRequestInfo(enrollKey);
      setEnrollRequestStatus(response?.status);
      if (response?.status!==200 && response?.status!==404) {
        Toast.create({ message: response?.message, color: 'danger', position: 'top', duration: 2500}).present();
        return;
      }
      setSigninRequest({signinType: 'e', requestKey: enrollKey, canRegisterDevice: response.data?.canRegisterDevice});

      // if the status isn't 200, there will not be any data.
      if (response?.status!==200) return;

      setRequestInfo({ name: response.data?.name, userAgreement: response.data?.userAgreement });
      setSignInButtonText(response.data?.signInButtonText);
      setLoginValue(response.data?.notificationValue);
      setSubdomain(response.data?.subdomain);
      setAccessToken(response.data.token, false);
      if (response.data?.buttonStyles) {
        setButtonStyles(response.data.buttonStyles);
      }

      if (response.data?.claimsDeviceCheckAdded===1) {
        logger.event('device-check');
      }
    }

    if (enrollKey) {
      __getEnrollRequestInfo(enrollKey);
      return;
    }

    if (history.location?.pathname==='/ahs') {
      // the manifest.json sets "/ahs" as the start_url. We are using this to know when the app was launched from a home screen
      setLaunchedFromHomeScreen(true);
    }

    // last resort, submit a request to see if there is a server side managed http only cookie
    setLoginValue('cookie');
    setSigninRequest({signinType: 'c', requestKey: 'none', canRegisterDevice: 'false'});
    // eslint-disable-next-line
  }, [enrollKey, authKey, customCode]);

  useEffect(() => {
    function setAgreementsJSX () {
      if (requestInfo?.userAgreement) {
        const replaceToken = '{{buttonText}}';
        const agreement = (requestInfo.userAgreement.indexOf(replaceToken) === -1)
                        ? requestInfo.userAgreement
                        : requestInfo.userAgreement.split(replaceToken).join(`<b>${signInButtonText}</b>`)
                        ;
        setUserAgreementInfo(
          <React.Fragment>
            <IonText className={styles.ContainerText}>
              <p className={styles.Agreements}>{parse(agreement)}</p>
            </IonText>
          </React.Fragment>
        )
        return;
      } 
      setUserAgreementInfo(<div className={styles.AgreementsFooter} />);
    };

    setAgreementsJSX();
  }, [requestInfo, signInButtonText]);

  const handleMobileNumberInputChange = (event) => {
    if (event.detail.value===undefined) return;
    setLoginValue(event.detail.value);
  };

  const signinButtonClick = async () => {
    // loginValue may be a moniker and will be updated with the responce from generateAuthOptions
    let login = loginValue;
    const loginType = loginTypeFromValue(login);
    if (!loginType) {
      logger.warn(`SignIn:signinButtonClick() Invalid Sign In value: ${login}`, login);
      Toast.create({ message: 'Invalid Sign In value', color: 'warning', position: 'top', duration: 1500}).present();
      return;
    }

    setProcessing(true); // disable Sign-In button
    
    // GET authentication options from the endpoint that calls
    // @simplewebauthn/server -> generateAuthenticationOptions()
    //todo: move to new services.js module...
    let authenticationOptsResp;
    try {
      authenticationOptsResp = await axios.get(
        ctxValue('IDENTITY_SERVICE') + `/webauth/generateAuthOptions/${loginType}/${login}/${subdomain}`,
        { withCredentials: true } // enables cross-site cookie sharing
      );
      if (authenticationOptsResp?.data?.loginValue) {
        // update 'login' from moniker to mobile number
        login = authenticationOptsResp.data.loginValue;
        setLoginValue(authenticationOptsResp.data.loginValue);
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        setProcessing(false);
        if (launchedFromHomeScreen && loginType==='loginMoniker') {
          setLoginMonikerAttemptFailed(true);
          return;
        }
        Toast.create({ message: 'Account info unavailable.', color: 'tertiary', duration: 1500, position: 'top' }).present();
        return;
      }
      logger.error(`SignIn: Login aborted due to /webauth/generateAuthOptions error - ${err}`, login);
      await logSigninEvent(signinRequest, 10.01);
      Toast.create({ message: 'Error: communicating with server. Please try again.', color: 'danger', duration: 1500, position: 'top' }).present();
      setProcessing(false);
      return;
    }

    // Pre-enrolled users initiated from notificationType NOT 'sms' will not have a device check, so this flag is used to show this alternative message to pre-enrolled users
    // See flag preenrollAuthenticated for notificationType equal 'sms'
    if (authenticationOptsResp?.data?.isFirstTimePreEnrolled === 1) {
      setProcessing(false);
      if (launchedFromHomeScreen) {
        await logSigninEvent(signinRequest, 10.02);
        logger.warn(`SignIn: Attempting to complete a first time pre-enroll sign in from A2HS is not supported (account was probably deleted and pre-enrolled).`, login);
        showIonAlert({
          header: `Sign In from Home Screen failed`,
          subHeader: `It appears this IDgo account was deleted and then pre-enrolled`,
          message: `This Home Screen application should be deleted and re-added from the new pre-enrolled account.`,
          buttons: [ {text:'OK', handler: () => {window.open('', '_self', ''); window.close()}} ]
        });
        return;
      }
      await logSigninEvent(signinRequest, 10.03);
      showIonAlert({
        header: `${requestInfo.name} Authentication Request`,
        subHeader: `Please follow the prompts for ${requestInfo.name} to securely authenticate yourself.`,
        message: `If you didn't make this request, select Cancel and call ${requestInfo.name} to protect your account. Otherwise, select Continue to complete your authentication.`,
        buttons: [ {text:'Cancel', handler: () => preenrolledContinuePrompt_Cancel(signinRequest)}
                 , {text:'Continue', handler: () => signInNextFactor()}
                 ]
      });
      return;
    }

    // Server has no FIDO credentials for this login, initate SMS verification code
    if (authenticationOptsResp?.data?.authOptions?.allowCredentials?.length === 0) {
      setProcessing(false);
      if (launchedFromHomeScreen) {
        await logSigninEvent(signinRequest, 10.05);
        logger.warn(`SignIn: No FIDO keys from A2HS is not supported (account was probably deleted and recreated).`, login);
        showIonAlert({
          header: `Sign In from Home Screen failed`,
          subHeader: `It appears this IDgo account was deleted and then recreated`,
          message: `This Home Screen application should be deleted and re-added from the new IDgo account.`,
          buttons: [ {text:'OK', handler: () => {window.open('', '_self', ''); window.close()}} ]
        });
        return;
      }
      // if this is an enrollment request, the Device Check was completed when we fetched the enrollment details
      if (signinRequest?.signinType==='e') {
        await logSigninEvent(signinRequest, 10.20);
        setProcessing(false);
        acceptAgreements();
        setFIDO(false);
        history.replace({pathname:'/add-fido', state: { signinRequest }, search: history.location.search});
        return;
      }
      // if this is a users first authentication request for a pre-enrolled user initiated over sms, offer to create a Passkey
      if (requestInfo?.preenrollAuthenticated===1) {
        await logSigninEvent(signinRequest, 10.24);
        setProcessing(false);
        acceptAgreements();
        setFIDO(false);
        history.replace({pathname:'/add-fido', state: { signinRequest }, search: history.location.search});
        return;
      }

      signInNextFactor();
      return;
    }

    let authenticationResp;
    const timerStart = Date.now();
    try {
      // Pass the options to the authenticator and wait for a response
      authenticationResp = await startAuthentication(authenticationOptsResp.data.authOptions);
    } catch (err) {
      /**
       * common use cases that cause errors:
       *   1. No Passkeys on this device
       *      a) May be this device was never setup
       *      b) User may have deleted Passkeys from Settings
       *   2. User cancels out of the platform authenticator dialog
       * Apple provides zero information to detect which use case above.
       */
      const timerEnd = Date.now();
      const duration = timerEnd - timerStart;
      const eventDetails = { userAgent: {OS: userAgent.getOS()}, error: {name: err?.name, message: err?.message, code: err?.code, cause: `${err?.cause}`, duration}};
      setProcessing(false);
      logger.warn(`SignIn: Login aborted due to startAuthentication(). Error name: '${err?.name}', message: ${err?.message}, code: ${err?.code}, cause: ${err?.cause}`, login);
      if (launchedFromHomeScreen) {
        await logSigninEvent(signinRequest, 10.07, eventDetails);
        logger.warn(`SignIn: Account Recovery not supported from A2HS (startAuthentication failed).`);
        showIonAlert({
          header: `Sign In from Home Screen failed`,
          subHeader: `Issue attempting to Sign In, perhaps this request was cancelled`,
          message: `If you didn't cancel the Sign In request, you may need to deleted this Home Screen application and re-add it.`,
          buttons: [ {text:'OK', handler: () => {}} ]
        });
        return;
      }
      await logSigninEvent(signinRequest, 10.08, eventDetails);
      showIonAlert({
        header: 'Sign-In Unsuccessful',
        subHeader: 'This may be due to using a new phone, Passkey issue, or choosing Cancel from the sign-in prompt.',
        message: 'If you would like to establish a Passkey, please select Continue, otherwise Cancel.',
        buttons: [ {text:'Cancel'  , handler: () => signInUnsuccessfulPrompt_Cancel(signinRequest)}
                 , {text:'Continue', handler: () => signInNextFactor()}
                ]
      });
      return;
    }

    // POST the response to the endpoint that calls
    // @simplewebauthn/server -> verifyAuthenticationResponse()
    //todo: move to new services.js module...
    let verifyAuthenticationResp;
    try {
      verifyAuthenticationResp = await axios.post(
        ctxValue('IDENTITY_SERVICE') + `/webauth/verifyAuthResponse/${loginType}/${login}/${subdomain}`,
        authenticationResp,
        { withCredentials: true }
      );
    } catch (err) {
      if (err?.response?.status === 401) {
        setProcessing(false);
        // credential did not match
        logger.warn(`SignIn: Login aborted due to verifyAuthResponse() error - ${err}, status: ${err?.response?.status}`, login);
        if (launchedFromHomeScreen) {
          await logSigninEvent(signinRequest, 10.10);
          logger.warn(`SignIn: Account Recovery not supported from A2HS (verifyAuthResponse failed).`);
          showIonAlert({
            header: `Sign In from Home Screen failed`,
            subHeader: `Issue attempting to Sign In`,
            message: `You may need to deleted this Home Screen application and re-add it.`,
            buttons: [ {text:'OK', handler: () => {}} ]
          });
          return;
        }
        await logSigninEvent(signinRequest, 10.11);
        showIonAlert({
          header: 'Sign-In Unsuccessful',
          subHeader: 'Passkey issue.',
          message: 'If you would like to establish a Passkey, please select Continue, otherwise Cancel.',
          buttons: ['Cancel', {text:'Continue', handler: () => signInNextFactor()}]
        });
        return;
      }
      await logSigninEvent(signinRequest, 10.12);
      logger.error(`SignIn: Login aborted due to verifyAuthResponse() - ${err}`, login);
      Toast.create({ message: `Oh no, something went wrong! ${err}}`, color: 'danger', duration: 4000, position: 'top' }).present();
      setProcessing(false);
      return;
    }

    // Show UI appropriate for the `verified` status
    if ( ! verifyAuthenticationResp || ! verifyAuthenticationResp.data ) {
      await logSigninEvent(signinRequest, 10.13);
      logger.error('SignIn: Login aborted due to verifyAuthResponse() missing response', login);
      Toast.create({ message: 'Oh no, something went wrong! Missing response from verifyAuthResponse()', color: 'danger', duration: 3000, position: 'top' }).present();
      setProcessing(false);
      return;
    }
    if ( ! verifyAuthenticationResp.data.verified ) {
      // This failed attempt is logged as an Error by the identity service and recorded in the signin-event-log
      await logSigninEvent(signinRequest, 10.14);
      Toast.create({ message: 'Oh-oh, attempt failed. Please try again.', color: 'warning', duration: 3000, position: 'top' }).present();
      setProcessing(false);
      return;
    }
    
    await logSigninEvent(signinRequest, 10.15);
    setProcessing(false);
    setAccessToken(verifyAuthenticationResp.data.token, true);
    acceptAgreements();
    setFIDO(true);

    //IG-2786 - remove 'qrcode' from the "online" logic to support the new Eltropy use case
    if (requestInfo?.notificationType==='popup') {
      const response = await verifyResponse(subdomain, requestInfo?.verificationRequestId, 'pass');
      if (response?.status!==201) {
        Toast.create({ message: response?.message, color: 'danger', position: 'top', duration: 10000}).present();
        return;
      }
      window.open('', '_self', '');
      window.close();
      return;
    }
    
    if (launchedFromHomeScreen) {
      history.replace({ pathname:'/notifications', search: history.location.search });
    } else if (subdomain) {
      history.replace({ pathname:'/dashboard', search: history.location.search, state:{ signinRequest }});
    } else {
      history.replace({ pathname:'/relying-party-list', state:{ refreshTimestamp:new Date(), signinRequest }});
    }
  };

  const signInNextFactor = async () => {
    // For now signing in with a Cookie and without a Passkey is not allowed
    if (signinRequest?.signinType==='c') {
      logger.warn(`SignIn: Sign-In from cookie without a Passkey not supported.`);
      showIonAlert({
        header: 'Sign-In Unsuccessful',
        subHeader: 'Passkey required',
        message: 'Sign In without a Passkey is not supported.',
        buttons: [ {text:'OK', handler: () => {}} ]
      });
      return;
    }
    // Check for previous Verification Cookie (verificationRequestId may be undefined)
    const authCookieResp = await loginPreviousVerificationCookie(requestInfo?.verificationRequestId);
    if (authCookieResp.status===200 && authCookieResp.data?.token) {
      await logSigninEvent(signinRequest, 10.30);
      setAccessToken(authCookieResp.data.token, false);
      history.replace({ pathname:'/dashboard', search: history.location.search, state:{ signinRequest }});
      return;
    }
    // Try a Silent Network Authentication - with User Consent


    // SMS OTP sign-in (legacy: 1st time Device Check verification process)
    await logSigninEvent(signinRequest, 10.06); // promptforSMSOTP
    setShowVerificationCodeAlert(true);
  };

  const signInWithVerificationCode = async (loginValue) => {
    // The following temporary flag will be used as a flag to pull SIM Swap data in the backend
    const userAgreementShown = (requestInfo?.userAgreement) ? true : undefined;
    const verificationInfo = await loginVerificationCodeStart(loginValue, userAgreementShown);
    if (verificationInfo.status === 200) {
      await logSigninEvent(signinRequest, 10.80, {signinRequest});
      history.replace(
        { pathname: '/verification-code'
        , state:
          { accountRecovery  : true
          , verificationInfo : verificationInfo.data
          , signinRequest
          }
        , search: history.location.search
        });
      return;
    }
    // Unable to initite the SMS OTP
    if (verificationInfo.status === 429) {
      await logSigninEvent(signinRequest, 10.81);
      Toast.create(
        { color: 'warning'
        , message: verificationInfo?.message
        , position: 'top'
        , buttons: [{side: 'end', icon: 'close', text: 'close', role: 'cancel' }]
        }
      ).present();
      return;
    }
    await logSigninEvent(signinRequest, 10.82);
    Toast.create({ message: `System error - ${verificationInfo?.message}`, color: 'danger', position: 'top', buttons: [{side: 'end', icon: 'close', text: 'close', role: 'cancel' }]}).present();
  };

  const preenrolledContinuePrompt_Cancel = async (signinRequestArg) => {
    await logSigninEvent(signinRequestArg, 10.04);
  };

  const signInUnsuccessfulPrompt_Cancel = async (signinRequestArg) => {
    await logSigninEvent(signinRequestArg, 10.09);
  };

  const renderExpiredRequest = () => {
    return <>
      <div className={styles.RequestExpired}>Sorry, this request has expired.</div>
    </>
  };

  const renderSignInPage = () => {
    //IG-2786 - remove 'qrcode' from the "online" logic to support the new Eltropy use case
    const onlineSignIn = (requestInfo?.notificationType==='popup');
    return <>
      <div className={styles.SignIn}>
        <div className={styles.BluePanel}>
          <div className={styles.ContainerImg}>
            <IonImg src={`https://mwlogos${ctxValue('LOGOENV').toLowerCase()}.z5.web.core.windows.net/${subdomain}.png?v3`} data-testid='id-go-logo' alt={`${subdomain}.png`} className={styles.IonImg} />
          </div>
          {showHeaders &&
            <IonText className={styles.ContainerText}>
              {onlineSignIn ?
                (
                  <>
                    <div className={styles.header3}>Sign in to <b>{requestInfo.name}</b> with IDgo
                      <span className={styles.TradeMark}>&trade;&nbsp;</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.header1}>Fast, Simple, Secure</div>
                    <div className={styles.header2}>User Authentication</div>
                  </>
                )
              }
            </IonText>
          }
          {userAgreementInfo}
        </div>
        <div className={styles.ContainerInputs}>
          { launchedFromHomeScreen && loginMonikerAttemptFailed &&
            <>
              <IonText className={styles.ContainerText}>
                <h2 data-testid='verification-code-text'>
                  Please enter your IDgo mobile number
                </h2>
              </IonText>
              <IonInput
                className={styles.IonInput}  
                onIonChange={handleMobileNumberInputChange}
                onFocus={()=>setShowHeaders(false)}
                data-testid='mobile-number-input'
                inputmode='numeric'
                maxlength='10'
                minlength='10'
                required='true'
                size='12'
                type='number'
              />
            </>
          }
          <IonButton
            className={styles.IonButton}
            style={{
              ...buttonStyles['inline'],
              ...(styledButtonHover ? buttonStyles[':hover'] : null),
              ...(styledButtonFocus ? buttonStyles[':focus'] : null),
            }}
            onMouseEnter={()=>{setStyledButtonHover(true)}}
            onMouseLeave={()=>{setStyledButtonHover(false)}}
            onIonFocus={()=>{setStyledButtonFocus(true)}}
            onIonBlur={()=>{setStyledButtonFocus(false)}}
            onClick={signinButtonClick}
            expand='block'
            disabled={processing}
            type='submit'
            data-testid='signin-button'
          >
            {onlineSignIn ? 'Continue with IDgo' : signInButtonText}
          </IonButton>
        </div>
      </div>
    </>
  };

  const renderExpiredOrSignInPage = () => {
    if (!signinRequest?.signinType) {
      return <div className={styles.loadingPage}></div>;
    }
    if (signinRequest?.signinType==='a') {
      if (authRequestStatus===404) return renderExpiredRequest();
      if (authRequestStatus!==200) return <></>;
      // fall-through on 200's
    } else if (signinRequest?.signinType==='e') {
      if (enrollRequestStatus===404) return renderExpiredRequest();
      if (enrollRequestStatus!==200) return <></>;
      // fall-through on 200's
    }
    return renderSignInPage();
  };

  return (
    <IonPage>
      <IonContent className="ion-padding" id={styles.ContentSignIn}>
        { renderExpiredOrSignInPage() }
      </IonContent>

      <IonAlert
        isOpen={showVerificationCodeAlert}
        onDidDismiss={() => setShowVerificationCodeAlert(false)}
        header={'Verification Code'}
        message={'We will send you a one time verification code to continue.'}
        buttons={[
          {
            text: 'OK',
            handler: () => { signInWithVerificationCode(loginValue) }
          }
        ]}
      />

      <IonAlert
        isOpen={userVerifyDialedNumberAlert}
        onDidDismiss={() => setUserVerifyDialedNumberAlert(false)}
        header={`You are speaking with ${requestInfo.agentName} today`}
        message={`To further protect this call </br>can you verify you contacted us at:</br><h3>${requestInfo.callCenterPhoneNumber}</h3>`}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              logger.eventNT('dialed-number-verified-no', signinRequest?.requestKey); // post message to IDgo Agent that the user selected 'No'
              showIonAlert({
                header: 'Suspicious Call',
                message: `We recommend you hang up</br>and call us back at:</br><h3>${requestInfo.callCenterPhoneNumber}</h3>.`,
                buttons: [{text:'Done', handler: () => setAuthRequestStatus(404)}]
              });
            }
          },
          {
            text: 'Yes',
            handler: () => {
              logger.eventNT('dialed-number-verified-yes', signinRequest?.requestKey); // post message to IDgo Agent that the user selected 'Yes'
              setUserVerifyDialedNumberAlert(false)
            }
          },
        ]}
      />

    </IonPage>
  );
}

export default SignIn;
